export const INIT = 'INIT';

export const UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE';
export const UPDATE_POPUP = 'UPDATE_POPUP';
export const UPDATE_TOOLTIP = 'UPDATE_TOOLTIP';

export const UPDATE_RUNNING_TIMER_ID = 'UPDATE_RUNNING_TIMER_ID';
export const UPDATE_RUNNING_FLAG = 'UPDATE_RUNNING_FLAG';
export const DECREASE_RUNNING_DURATION = 'DECREASE_RUNNING_DURATION';

export const UPDATE_SELECTING_TIMER_ID = 'UPDATE_SELECTING_TIMER_ID';

export const INIT_EDITOR = 'INIT_EDITOR';
export const UPDATE_EDITOR_IS_MORE_SETTINGS_SHOWN = 'UPDATE_EDITOR_IS_MORE_SETTINGS_SHOWN';

export const UPDATE_EDITOR_NAME = 'UPDATE_EDITOR_NAME';
export const UPDATE_EDITOR_DURATION = 'UPDATE_EDITOR_DURATION';
export const UPDATE_EDITOR_REMINDER_MESSAGE = 'UPDATE_EDITOR_REMINDER_MESSAGE';
export const UPDATE_EDITOR_REMINDER_CUSTOM_MESSAGE = 'UPDATE_EDITOR_REMINDER_CUSTOM_MESSAGE';
export const UPDATE_EDITOR_REMINDER_MESSAGE_DISPLAY_DURATION = 'UPDATE_EDITOR_REMINDER_MESSAGE_DISPLAY_DURATION';
export const UPDATE_EDITOR_REMINDER_CUSTOM_MESSAGE_DISPLAY_DURATION = 'UPDATE_EDITOR_REMINDER_CUSTOM_MESSAGE_DISPLAY_DURATION';
export const UPDATE_EDITOR_REMINDER_SOUND = 'UPDATE_EDITOR_REMINDER_SOUND';
export const UPDATE_EDITOR_REMINDER_REPETITIONS = 'UPDATE_EDITOR_REMINDER_REPETITIONS';
export const UPDATE_EDITOR_REMINDER_INTERVAL = 'UPDATE_EDITOR_REMINDER_INTERVAL';
export const UPDATE_EDITOR_NEXT_TIMER_ID = 'UPDATE_EDITOR_NEXT_TIMER_ID';
export const UPDATE_EDITOR_NEXT_TIMER_STARTS_BY = 'UPDATE_EDITOR_NEXT_TIMER_STARTS_BY';

export const UPDATE_EDITOR_SELECTING_REMINDER_KEY = 'UPDATE_EDITOR_SELECTING_REMINDER_KEY';
export const UPDATE_EDITOR_REMINDER_IS_MORE_OPTIONS_SHOWN = 'UPDATE_EDITOR_REMINDER_IS_MORE_OPTIONS_SHOWN';

export const ADD_TIMER = 'ADD_TIMER';
export const EDIT_TIMER = 'EDIT_TIMER';
export const DELETE_TIMER = 'DELETE_TIMER';
export const MOVE_TIMER_UP = 'MOVE_TIMER_UP';
export const MOVE_TIMER_DOWN = 'MOVE_TIMER_DOWN';

export const ADD_EDITOR_REMINDER = 'ADD_EDITOR_REMINDER';
export const DELETE_EDITOR_REMINDER = 'DELETE_EDITOR_REMINDER';
export const MOVE_EDITOR_REMINDER_UP = 'MOVE_EDITOR_REMINDER_UP';
export const MOVE_EDITOR_REMINDER_DOWN = 'MOVE_EDITOR_REMINDER_DOWN';

export const IMPORT_DATA = 'IMPORT_DATA';
export const RESET_DATA = 'RESET_DATA';
